import {
    getAllAppointmentsAPI,
    createAppointmentAPI,
    deleteAppointmentsAPI,
    getBlazeZipcodesAPI,
    saveBlazeZipcodesAPI
} from "../api/appointment";

import { toast } from 'react-toastify';

export const GET_ALL_APPOINTMENTS = 'GET_ALL_APPOINTMENTS';
export const GET_ALL_APPOINTMENTS_SUCCESSFULL = 'GET_ALL_APPOINTMENTS_SUCCESSFULL';
export const GET_ALL_APPOINTMENTS_FAILED = 'GET_ALL_APPOINTMENTS_FAILED';

export function getAllAppointments(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_APPOINTMENTS
        })
        return new Promise((resolve, reject) => {
            getAllAppointmentsAPI(data)
                .then((res) => {
                    //User Logged in sucessfully 
                    dispatch({
                        type: GET_ALL_APPOINTMENTS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_APPOINTMENTS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

export function createAppointment(data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            createAppointmentAPI(data, id)
                .then((res) => {
                    let txt = "Added"
                    if (id) {
                        txt = "Updated"
                    }
                    toast.success(`Service Area ${txt} successfully.`)
                    return resolve(res)
                })
                .catch(err => {
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}


export const DELETE_APPOINTMENTS = 'DELETE_APPOINTMENTS';
export const DELETE_APPOINTMENTS_SUCCESSFULL = 'DELETE_APPOINTMENTS_SUCCESSFULL';
export const DELETE_APPOINTMENTS_FAILED = 'DELETE_APPOINTMENTS_FAILED';

export function deleteAppointments(data) {
    return dispatch => {
        dispatch({
            type: DELETE_APPOINTMENTS
        })
        return new Promise((resolve, reject) => {
            deleteAppointmentsAPI(data)
                .then((res) => {
                    dispatch({
                        type: DELETE_APPOINTMENTS_SUCCESSFULL,
                        data: res
                    })
                    toast.success(`Service Area deleted successfully.`)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_APPOINTMENTS_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const GET_BLAZE_ZIPCODES = 'GET_BLAZE_ZIPCODES'

export function getBlazeZipcodes(data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            getBlazeZipcodesAPI(data, id)
                .then(({ values = "" }) => {
                    dispatch({
                        type: GET_BLAZE_ZIPCODES,
                        data: values
                    })
                    resolve(values)
                })
                .catch(err => {
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const SAVE_BLAZE_ZIPCODES = 'SAVE_BLAZE_ZIPCODES'
export const SAVE_BLAZE_ZIPCODES_SUCCESS = 'SAVE_BLAZE_ZIPCODES_SUCCESS'
export const SAVE_BLAZE_ZIPCODES_FAILED = 'SAVE_BLAZE_ZIPCODES_FAILED'

export function saveBlazeZipcodes(data, id) {
    return dispatch => {
        dispatch({
            type: SAVE_BLAZE_ZIPCODES
        })
        return new Promise((resolve, reject) => {
            saveBlazeZipcodesAPI(data, id)
                .then(({ values = "" }) => {
                    dispatch({
                        type: SAVE_BLAZE_ZIPCODES_SUCCESS,
                        data: values
                    })
                    toast.success(`BLAZE zipcodes saved successfully.`)
                    resolve(values)
                })
                .catch(err => {
                    dispatch({
                        type: SAVE_BLAZE_ZIPCODES_FAILED,
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}