import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from "lodash";

import TruckFormUI from './TruckFormUI';
import { GOOGLE_MAPS_CONFIG, GOOGLE_PLACES_KEY, staticOrderingHours, staticTimes, staticTimeZones } from '../../constants';
import { validateTrucksData } from '../../utilities/validations/trucks';
import { createNewTruck, getAllTerminals, googlePlaces } from '../../redux/actions/trucks';
import { openCustomModalDialog } from '../../redux/actions/common';
import { getAllAppointments } from '../../redux/actions/appointments';

let initialData = {
    orderingHours: [...staticOrderingHours],
    zipcode: [],
    name: "",
    timeZone: {},
    terminal: {},
    address: {},
    location: {}
}


function AddTruck(props) {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch();

    let map;

    const { allTerminals = {} } = useSelector(state => state.trucks)
    const { values: allTerminalList = [] } = allTerminals || {}

    const [data, setData] = useState({ ...initialData })
    const [errors, setErrors] = useState({})

    useEffect(() => {
        dispatch(getAllTerminals()).then(() => { }).catch(() => { })
        dispatch(getAllAppointments()).then(() => { }).catch(() => { })
    }, [])

    useEffect(() => {
        if (window.google) {
            initMap();
        }
    }, [window.google])


    const handleChangeTerminal = (e, val) => {
        setData({
            ...data,
            terminal: val
        })
    }

    const handleChange = (key, value) => {
        setData({
            ...data,
            [key]: value
        })
        setErrors({
            ...errors,
            [key]: ""
        })
    }

    const handleChangeOrderingHours = (key, val, index) => {

        let updatedData = { ...data }
        let updatedOrdersHours = [...(updatedData.orderingHours || [])]
        let updatedDayData = { ...updatedOrdersHours[index] }

        updatedDayData = {
            ...updatedDayData,
            [key]: (val && val.value) || ""
        }

        updatedOrdersHours[index] = updatedDayData
        updatedData.orderingHours = [...updatedOrdersHours]

        setData(updatedData)
    }

    const handleChangeLocation = (val) => {
        let obj = {
            key: GOOGLE_PLACES_KEY,
            address: val && val.description || ""
        }

        if (!isEmpty(val)) {
            dispatch(googlePlaces(obj))
                .then(async (res) => {
                    let addrObj = res && res.results && res.results[0] && res.results[0] || {}
                    let geomerty = addrObj && addrObj.geometry && addrObj.geometry.location

                    let locationData = {
                        ...data,
                        location: {
                            lat: geomerty.lat,
                            long: geomerty.lng
                        },
                        address: val
                    }
                    await setData(locationData)
                    await initMap(locationData.location)
                })
                .catch(() => { })
        }

    }

    function initMap(locationObj = {}) {
        const target = document.getElementById("pe-maps")
        const center = {
            lat: (locationObj && locationObj.lat) || 34.07624029999999,
            lng: (locationObj && locationObj.long) || -118.3103015
        }

        const config = {
            ...GOOGLE_MAPS_CONFIG,
            center,
            zoom: 12,
        }

        map = new window.google.maps.Map(target, config);
        new window.google.maps.Marker({
            position: center,
            map,
            // icon: {
            //     // url: "/images/marker.svg",
            //     scaledSize: new window.google.maps.Size(40, 40), // scaled size
            // }
        });
    }

    const handleCancel = () => {
        history.goBack();
    }

    const handleAddZipcode = () => {
        let obj = {
            selectedZipcode: data.zipcode || [],
            addZipcode: handleChange
        }
        dispatch(openCustomModalDialog(obj, "AddTruckZipcode"))
    }

    const handleDeleteZipCode = (index) => {
        let allZipCodes = [...(data.zipcode || [])]
        allZipCodes.splice(index, 1)
        setData({
            ...data,
            zipcode: allZipCodes
        })
    }

    const handleSubmit = () => {
        const { errors, isValid } = validateTrucksData(data)
        setErrors(errors);

        if (isValid) {
            let updatedOrdersHours = data.orderingHours.map(v => { const { _id, ...rest } = v; return rest })

            let obj = {
                name: data.name || "",
                timeZone: data.timeZone.label || "",
                zipcode: data.zipcode || [],
                terminalId: data.terminal.id || "",
                terminalName: data.terminal.name || "",
                orderingHours: updatedOrdersHours || [],
                location: { lat: `${data.location.lat || ""}`, long: `${data.location.long || ""}` },
                address: data.address || {}
            }

            dispatch(createNewTruck(obj)).then(() => {
                history.goBack();
            }).catch(() => { })

        }
    }

    return (
        <Grid container component="main" className={`${classes.root} add-trucks-container`}>
            <Grid item xs={12} className="">
                <Grid item xs={12} className="">
                    <Grid item xs={12} className="top-sec">
                        <div onClick={() => { history.goBack() }} className="cur-pointer d-flex align-center">
                            <KeyboardBackspaceIcon />
                            <span className="back-text">back</span>
                        </div>
                        <div className="right-sec">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { handleSubmit() }}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { handleCancel() }}
                            >
                                Cancel
                            </Button>

                        </div>
                    </Grid>


                    <Grid item xs={12} className="truck-form-container">
                        <TruckFormUI
                            data={data}
                            allTerminalList={allTerminalList || []}
                            allTimeZones={staticTimeZones || []}
                            allTimes={staticTimes || []}
                            errors={errors}
                            handleChangeterminal={handleChangeTerminal}
                            handleChange={handleChange}
                            handleChangeOrderingHours={handleChangeOrderingHours}
                            handleDeleteZipCode={handleDeleteZipCode}
                            handleChangeLocation={handleChangeLocation}
                            handleAddZipcode={handleAddZipcode}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AddTruck

