import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        //  margin: theme.spacing(1),
        //  minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function ({ label = 'Label', name = '', errors = '', onChange = () => { }, value = '', menuItem = [{ title: 'Title1', value: 'value1' }, { title: 'Title2', value: 'value2' }], ...rest }) {
    const classes = useStyles();
    return (
        <>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={value}
                    onChange={onChange}
                    label={label}
                    {...rest}
                >
                    {/* <MenuItem value="">
                    <em>None</em>
                </MenuItem> */}
                    {
                        menuItem && menuItem.map((val, index) => {
                            return (
                                <MenuItem value={val && val.value || ''} key={index}>{val && val.title || ''}</MenuItem>
                            )
                        })
                    }

                </Select>
                {errors ? <span className="error">{errors}</span> : null}
            </FormControl>
        </>


    );
}
