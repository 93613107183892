import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as productsActions from "../../redux/actions/products";
import { numRegex } from "../../utils"
import CustomInput from '../../components/common/CustomInput';
import EditIcon from '@material-ui/icons/Edit';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { Grid, Paper } from '@material-ui/core';

function Setting() {
  const dispatch = useDispatch();
  const [editThreshold, setEditThreshold] = useState(false);
  const { productThreshold } = useSelector(state => state.products);
  const [thresholdValue, setThresholdValue] = useState(null);

  useEffect(() => {
    dispatch(productsActions.getThreshold());
  }, [dispatch]);

  useEffect(() => {
    if (productThreshold?.threshold) setThresholdValue(productThreshold?.threshold)
  }, [productThreshold]);

  const onChnageThreshold = (e) => {
    const { value } = e.target;
    const numValue = Number(value);
    if (!(numRegex.test(numValue)) || numValue < 0) return;
    setThresholdValue(value);
  };

  return (
    <>
      <Paper elevation={3} className='setting-top'>
        <Grid container className='threshold-box' >
          <Grid item xs={4}>
            <div>Threshold value</div>
          </Grid>
          <Grid item xs={4}>
            {
              editThreshold ?
                <div>
                  <CustomInput
                    size="small"
                    value={thresholdValue}
                    onChange={onChnageThreshold}
                  />
                </div>
                :
                <div>{thresholdValue}</div>
            }
          </Grid>
          <Grid item xs={4}>
            {
              editThreshold ?
                <div >
                  <div
                    onClick={() => { setEditThreshold(false) }}
                    className=" cur-pointer done-icon" 
                  >
                    <DoneIcon />
                  </div>
                  <div
                    onClick={() => { setEditThreshold(false) }}
                    className=" cur-pointer delete-icon"
                  >
                    <ClearIcon />
                  </div>
                </div>
                :
                <div
                  onClick={() => { setEditThreshold(true) }}
                  className=" cur-pointer edit-icon" 
                >
                  <EditIcon />
                </div>

            }
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}


export default Setting;