import {
    GET_BLAZE_ZIPCODES,
    GET_ALL_APPOINTMENTS,
    SAVE_BLAZE_ZIPCODES,
    SAVE_BLAZE_ZIPCODES_SUCCESS,
    SAVE_BLAZE_ZIPCODES_FAILED,
    GET_ALL_APPOINTMENTS_FAILED,
    GET_ALL_APPOINTMENTS_SUCCESSFULL
} from "../actions/appointments"



const initialState = {
    isLoading: false,
    allAppointments: {},
    blazeZipcode: ''
}

export default function appointments(state = { ...initialState }, action = {}) {
    switch (action.type) {

        //Get all Appointments
        case GET_ALL_APPOINTMENTS:
            return { ...state, isLoading: true }
        case GET_ALL_APPOINTMENTS_SUCCESSFULL:
            return { ...state, isLoading: false, allAppointments: action.data }
        case GET_ALL_APPOINTMENTS_FAILED:
            return { ...state, isLoading: false }

        // get blaze zipcodes
        case GET_BLAZE_ZIPCODES:
            return { ...state, blazeZipcode: action.data }

        // save blaze zipcodes
        case SAVE_BLAZE_ZIPCODES:
            return { ...state, isLoading: true }
        case SAVE_BLAZE_ZIPCODES_SUCCESS:
            return { ...state, isLoading: false, blazeZipcode: action.data }
        case SAVE_BLAZE_ZIPCODES_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}