import {
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_FAILED,
    GET_ALL_ORDERS_SUCCESSFULL,
} from '../actions/orders';

const initialState = {
    isLoading: false,
    allOrders: {},
}

export default function orders(state = { ...initialState }, action = {}) {
    switch (action.type) {
        //Get all orders
        case GET_ALL_ORDERS:
            return { ...state, isLoading: true }

        case GET_ALL_ORDERS_SUCCESSFULL:
            return { ...state, isLoading: false, allOrders: action.data }

        case GET_ALL_ORDERS_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}