import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Grid, Box, Paper } from '@material-ui/core';
import CommonLoading from "../../components/common/CommonLoading"

import * as commonActions from "../../redux/actions/common"
import * as productsActions from "../../redux/actions/products"
import { useDispatch, useSelector } from 'react-redux';
import DraggableList from './DraggableList';

function ManageCategoriesPage(props) {
    const classes = useStyles();
    const dispatch = useDispatch()

    const [category, setCategory] = useState([])

    const { allCategoryData, isLoading, allBlazeCategoryData, categoryImages = [] } = useSelector(state => state.products)


    const { values: categoryValues } = allCategoryData
    const { values = [] } = allBlazeCategoryData


    useEffect(() => {
        dispatch(productsActions.getAllCategory())
        dispatch(productsActions.getAllBlazeCategory())
        if (Array.isArray(categoryImages) && !categoryImages.length) {
            dispatch(productsActions.getAllCategoryImages())
        }
    }, [])

    useEffect(() => {
        setCategory(categoryValues)
    }, [categoryValues])



    const handleChangeCategory = (e, index) => {
        const { name, value } = e.target

        let updatedCategory = [...category]
        let catObj = { ...(updatedCategory[index]) }

        catObj = {
            ...catObj,
            [name]: value
        }

        updatedCategory[index] = catObj

        setCategory(updatedCategory)
    }

    const handleChangeSubCategory = (e, val, index) => {

        let newVal = val && val.length && val.map(v => { return { id: v.id, name: v.name } }) || []

        let updatedCategory = [...category]
        let catObj = { ...(updatedCategory[index]) }

        catObj = {
            ...catObj,
            blazeCategories: [...newVal]
        }

        updatedCategory[index] = catObj

        setCategory(updatedCategory)

    }

    const handleConfirmation = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { handleDeleteCategory(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const handleDeleteCategory = (id) => {
        dispatch(productsActions.deleteCategory(id)).then(() => {
            dispatch(productsActions.getAllCategory())
        }).catch(() => { })
    }

    const handleSubmit = () => {
        let newOrderedCat = [...category].map((v, ind) => { return { ...v, order: ind + 1 } })
        dispatch(productsActions.updateCategories(newOrderedCat))
    }

    const onUpdateCategoryOrder = (updatedCategory) => {
        setCategory(updatedCategory)
    }

    const handleEditCategory = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddCategory"))
    }


    let updatedOptions = [...(values || [])]
    let selectedBlazeCategory = []
    category && category.length && category.map((v) => selectedBlazeCategory.push(v.blazeCategories))
    selectedBlazeCategory = selectedBlazeCategory.flat(1)

    selectedBlazeCategory = selectedBlazeCategory.flat(1)
    var array3 = updatedOptions.filter((obj) => {
        return selectedBlazeCategory.findIndex((fnd) => {
            return (fnd.id == obj.id)
        }) == -1;
    });

    let updatedOptionsValues = [...array3]


    return (
        <Grid container component="main" className={`${classes.root} manage-category-page-container`}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} className="add-category-btn">
                        <Button
                            onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddCategory")) }}
                            variant="contained"
                            color="primary"
                        >
                            Add Category
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {Array.isArray(category) && category.length ?
                <Grid item xs={12} sm={12} md={12} lg={12} className="m-a">
                    <Paper>
                        <Box p={4}>
                            <DraggableList
                                category={category}
                                values={updatedOptionsValues}
                                onUpdateCategoryOrder={onUpdateCategoryOrder}
                                handleEditCategory={handleEditCategory}
                                handleDeleteCategory={handleConfirmation}
                                handleChangeSubCategory={handleChangeSubCategory}
                            />
                            <div className="save-button-div">
                                <Button
                                    onClick={() => { handleSubmit() }}
                                    variant="contained"
                                    color="primary"
                                >Save</Button>
                            </div>
                        </Box>
                    </Paper>
                </Grid>
                :
                <Grid item xs={12}>
                    <Box mt={2}>
                        <div className="no-data">
                            <img src="/images/noDataFound.svg" alt="" />
                            <Typography variant="h5">No categories Yet</Typography>
                        </div>
                    </Box>
                </Grid>}
            <CommonLoading isLoading={isLoading} />
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        // height: '100vh',
    },

}));


export default ManageCategoriesPage

