import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import './App.css';
import './index.css';
import "./styles/index.scss"
import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer } from 'react-toastify';
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./redux/store";
toast.configure()

function App() {

    const store = configureStore()

    return (
        <div className="App">
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    {/* <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    /> */}
                    <Routes />
                    <ToastContainer />

                </ConnectedRouter>
            </Provider>
        </div>
    );
}


ReactDOM.render(<App />, document.getElementById('root'))
