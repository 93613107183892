import { apiDelete, apiGet, apiPost, apiPut } from '../../utils';

export function getAllAppointmentsAPI(data) {
    return apiGet('api/v1/admin/zipcode', data);
}

export function createAppointmentAPI(data, id) {
    if (id) {
        return apiPut(`api/v1/admin/zipcode/${id}`, data);
    }
    return apiPost('api/v1/admin/zipcode', data);
}

export function deleteAppointmentsAPI(id) {
    return apiDelete(`api/v1/admin/zipcode/${id}`);
}

export function saveBlazeZipcodesAPI(data) {
    return apiPost(`api/v1/admin/zipcode/saveZipcode`, data);
}

export function getBlazeZipcodesAPI() {
    return apiGet(`api/v1/admin/zipcode/getZipcode`);
}