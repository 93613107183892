import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Button, Typography, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CustomMultiSelect from '../../components/common/CustomMultiSelect';


function AddTruckZipcode(props) {
    const classes = useStyles();

    const [arr, setArr] = useState([])

    const { allAppointments, isLoading } = useSelector(state => state.appointments)
    const { values = [] } = allAppointments

    const { modalData } = useSelector(state => state.common)

    const { selectedZipcode = [], addZipcode } = modalData;

    const handleChange = (e, newVal) => {
        setArr(newVal)
    }

    const handleSubmit = () => {
        let newArr = arr && arr.length && arr.map(v => { return v.zipcode }) || []
        addZipcode("zipcode", [...selectedZipcode, ...newArr])

        props.closeModal();
    }


    let filteredZipcodes = values && values.length && values.filter(v1 => {
        return selectedZipcode.findIndex(v2 => {
            return (v2 == v1.zipcode)
        }) == -1
    }) || []

    let updatedValues = [...filteredZipcodes];
    // updatedValues.length = 10;

    return (
        <Grid container component="main" className={`${classes.root} add-zipcode-modal-container`}>

            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">Add ZipCode</Typography>
            </Grid>
            <Grid item xs={12} className="add-category-input">
                <Grid item xs={12}>
                    <CustomMultiSelect
                        options={[...updatedValues] || []}
                        label="Add Zipcodes"
                        name={"zipcode"}
                        handleChange={(e, val) => { handleChange(e, val) }}
                        value={arr || []}
                        multiple
                    />
                </Grid>

            </Grid>
            <Grid item xs={12} className="d-flex jc-ctr pb-2 add-button">
                <Button variant="contained" color="primary" fullWidth onClick={() => { handleSubmit() }}>Submit</Button>
            </Grid>
        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AddTruckZipcode