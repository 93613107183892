import { isEmpty } from "lodash";



export function validateZipcodeData(data = {}) {
    let errors = {};


    if (!data.zipcode) {
        errors.zipcode = "Zipcode is required!";
    }

    if (data.zipcode && (data.zipcode.length < 2 || data.zipcode.length > 11)) {
        errors.zipcode = "Enter a valid zipcode.";
    }

    if (!data.serviceArea) {
        errors.serviceArea = "Service area is required!";
    }

    if (!data.orderMin) {
        errors.orderMin = "Order Minimum is required!";
    }


    return {
        isValid: isEmpty(errors),
        errors
    }
}