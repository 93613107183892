import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import auth from './auth';
import products from './products';
import common from './common';
import appointments from './appointments';
import promotions from './promotions';
import user from "./user"
import trucks from "./trucks"
import notifications from './notifications';
import orders from './orders'
import deliveryHours from './deliveryHours';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth,
    products,
    common,
    appointments,
    promotions,
    user,
    trucks,
    notifications,
    orders,
    deliveryHours
});

export default rootReducer;