import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles({
    list: {
        width: 600,
        padding: 25,
    },
    fullList: {
        width: 'auto',
    },
});

export default function TemporaryDrawer(props) {
    const classes = useStyles();
    const { children, anchor = 'right', open = false } = props;

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    useEffect(() => {
        setState({ ...state, [anchor]: open });
    }, [anchor, open])

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <div>

            <React.Fragment key={anchor}>
                <Drawer anchor={anchor} open={state[anchor]} >
                    <div
                        className={clsx(classes.list, {
                            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                        })}
                        role="presentation"
                    // onClick={toggleDrawer(anchor, false)}
                    //onKeyDown={toggleDrawer(anchor, false)}
                    >
                        <List>
                            {children}
                        </List>

                    </div>
                </Drawer>
            </React.Fragment>

        </div>
    );
}
