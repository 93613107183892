import { isEmpty } from "lodash";



export function validatePromotionData(data = {}) {
    let errors = {};
    if (!data.name) {
        errors.name = "Title is required!";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}