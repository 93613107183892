import React from 'react';
import { Grid, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomTable from "../../components/common/CustomTable"
import EditIcon from '@material-ui/icons/Edit';
import { trimText } from '../../utils';
import CustomMenu from '../../components/common/CustomMenu';



function AllTrucks(props) {
    const classes = useStyles();

    const { trucksValues, isLoading, handleEdit } = props

    return (
        <Grid container component="main" className={`${classes.root} all-trucks-container`}>
            <Grid item xs={12} className="trucks-table">
                <CustomTable
                    tableHeading={["Terminal Name", "Blaze Terminal Name", "Blaze Terminal Id", "ZipCodes", "Location", "Action"]}
                    rowsData={createDataTrucks(trucksValues, { handleEdit })}
                    isLoading={isLoading}
                />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AllTrucks




function createDataTrucks(data, action) {
    const { handleEdit } = action

    const onshowMoreZip = (array = [], index) => {
        let options = [...array];
        options.splice(0, 2)
        return <CustomMenu
            spanElement={<span className="color-theme font-bold custom-span-element">{` + ${options.length} More`}</span>}
            index={index}
            options={options}
            type="show-more"
        />
    }

    return data && data && data.length ?
        data.map((item, index) => {
            const { name, terminalName, terminalId, zipcode, location } = item
            // let zipCodesJoined = zipcode.join(", ")

            const showZipCodes = zipcode.map(item => item) || []
            const showMoreZipCodes = zipcode.length > 2;
            return <React.Fragment key={index}>
                <TableCell >{name || ""}</TableCell>
                <TableCell>{terminalName || ""}</TableCell>
                <TableCell>{terminalId || "-"}</TableCell>
                <TableCell>
                    <p className="custom-menu-p-tag">
                        {[...showZipCodes].slice(0, 2).join(', ')}
                        {showMoreZipCodes ? ',' : null}
                        {showMoreZipCodes ? onshowMoreZip(showZipCodes, index) : null}
                    </p>
                </TableCell>
                <TableCell>
                    <div>
                        <span className="d-block">Lat - ({location.lat || "N/A"})</span>
                        <span>Long - ({location.long || "N/A"})</span>
                    </div>
                </TableCell>
                <TableCell>
                    <div className="cur-pointer" onClick={() => { handleEdit(item) }}>
                        <EditIcon />
                    </div>
                </TableCell>
            </React.Fragment>
        }) : []
}