import {
    getAllUsersAPI,
    updateNotificationAPI
} from "../api/notifications";

import { toast } from 'react-toastify';


export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESSFULL = 'GET_ALL_USERS_SUCCESSFULL';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';

export function getAllUsers(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_USERS
        })
        return new Promise((resolve, reject) => {
            getAllUsersAPI(data)
                .then((res) => {
                    //User Logged in sucessfully 
                    dispatch({
                        type: GET_ALL_USERS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_USERS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATIONS_SUCCESSFULL = 'UPDATE_NOTIFICATIONS_SUCCESSFULL';
export const UPDATE_NOTIFICATIONS_FAILED = 'UPDATE_NOTIFICATIONS_FAILED';

export function updateNotification(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_NOTIFICATION
        })
        return new Promise((resolve, reject) => {
            updateNotificationAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_NOTIFICATIONS_SUCCESSFULL,
                        data: res
                    })
                    toast.success(res.message || "Notification send successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_NOTIFICATIONS_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}


