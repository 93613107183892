import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, CircularProgress } from '@material-ui/core';



const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});


function stableSort(array) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {

        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function CustomProductTable(props) {
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const {
        tableHeading = [],
        rowsData = [],
        total = 0,
        onChangePagination = () => { },
        filters = {},
        onChangeRowsPerPage = () => { },
        isLoading = false
    } = props;


    useEffect(() => {
        setPage(0)
    }, [rowsData])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="cus-table">
            <TableContainer className="box-shadow-none" component={Paper}>
                <Table className={classes.table} aria-label="simple table">

                    <TableHead>
                        <TableRow>
                            {
                                tableHeading.map((data, index) => {
                                    return (<TableCell key={index}>{data}</TableCell>)
                                })
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            isLoading ?
                                <TableRow>
                                    <TableCell colSpan="6">
                                        <span className="table-loading">
                                            <CircularProgress />
                                        </span>
                                    </TableCell>
                                </TableRow>
                                :
                                rowsData && rowsData.length ?
                                    stableSort(rowsData).map((row, index) => {
                                        return (<TableRow key={index}>
                                            {row}
                                        </TableRow>
                                        )
                                    }
                                    )
                                    :
                                    <TableRow>
                                        <TableCell colSpan={tableHeading.length} align="center">
                                            <span className="no-data-found">
                                                <p>No Data Found</p>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={filters.limit}
                page={filters.page}
                onChangePage={onChangePagination}
                onChangeRowsPerPage={onChangeRowsPerPage}
                className="cus-table-pagination"
            />
        </div>
    );
}