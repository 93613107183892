import { Grid, TableCell, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux';
import CustomTable from '../../components/common/CustomTable'


export default function ViewItems() {
    const { modalData = {} } = useSelector(state => state.common)
    const { items = [] } = modalData;

    return (
        <Grid container>
            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">Items</Typography>
            </Grid>
            <Grid item xs={12} className="pt-2 pb-2">
                <CustomTable
                    tableHeading={["Name", "Quantity", "Total Price"]}
                    rowsData={createViewItems(items)}
                    isLoading={false}
                    showPagination={false}
                />
            </Grid>
        </Grid>
    )
}

function createViewItems(data) {
    return data && data && data.length ?
        data.map((item, index) => {
            const {
                quantity = 0,
                unitPrice = 0,
                product = {}
            } = item
            return <React.Fragment key={index}>
                <TableCell>{product && product.name || '-'}</TableCell>
                <TableCell>{quantity || 0}</TableCell>
                <TableCell>${(!isNaN(unitPrice) && unitPrice.toFixed(2)) || 0}</TableCell>
            </React.Fragment>
        }) : []
}