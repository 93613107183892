import React, { useState, useEffect } from 'react';
import { Button, Typography, Grid, TextField } from '@material-ui/core';
import * as actions from "../../redux/actions/products"
import * as commonActions from "../../redux/actions/common"
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { validateBrandData } from '../../utilities/validations/brand';

const initialBrand = {
    brandId: "",
    brandName: "",
    description: ""
}

export default function AddBrandModal(props) {
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { allBrands = {}, allBmwBrands = {} } = useSelector(state => state.products)
    const { values = [] } = allBrands || {}
    const { values: bmwBrands = [] } = allBmwBrands || {}

    const [brand, setBrand] = useState({ ...initialBrand })
    const [errors, setErrors] = useState({ ...initialBrand })

    const { title = "Add", data: editData = {} } = modalData

    useEffect(() => {
        if (title === 'Edit') {
            const { brandId = '', brandName = '', description = '' } = editData || {}
            setBrand({
                brandId,
                brandName,
                description
            })
        }
    }, [])

    const onChangehandler = (type, value) => {
        if (type === 'brandId') {
            setBrand({ ...brand, ...value })
            setErrors({ ...errors, brandId: "", brandName: "" })
            return
        }
        setBrand({ ...brand, [type]: value })
        setErrors({ ...errors, [type]: '' })
    }

    const onAddBrand = () => {
        const { isValid = false, errors = {} } = validateBrandData(brand)
        if (isValid) {
            if (title === 'Edit') {
                return dispatch(actions.updateBrandFromBmw(brand)).then(res => {
                    dispatch(commonActions.closeCustomModalDialog())
                    dispatch(actions.getAllBmwBrands())
                })
            }
            dispatch(actions.addBrandToBmw(brand)).then(res => {
                dispatch(commonActions.closeCustomModalDialog())
                dispatch(actions.getAllBmwBrands())
            })
        } else {
            setErrors(errors)
        }
    }

    const filtersBrands = (Array.isArray(values) && Array.isArray(bmwBrands) && values.filter(blazeBrand => !bmwBrands.find(bmwBrand => blazeBrand.id === bmwBrand.brandId))) || []
    const brandList = filtersBrands.map(item => ({ brandId: item.id, brandName: item.name }))

    return (
        <Grid container component="main" className="add-zipcode-modal-container">

            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} Brand</Typography>
            </Grid>
            <Grid item xs={12} className="add-category-input">
                <Grid item xs={12}>
                    <Autocomplete
                        options={brandList}
                        getOptionLabel={(option) => option.brandName}
                        fullWidth
                        disableClearable
                        value={brand}
                        onChange={(e, value) => onChangehandler('brandId', value)}
                        getOptionSelected={(option, value) => option.brandId === value.brandId}
                        renderInput={(params) => <TextField {...params} label="Select Blaze Brand" variant="outlined" />}
                    />
                    {errors.brandId && <p className="error">{errors.brandId}</p>}
                </Grid>
                <Grid item xs={12} className="pt-2">
                    <TextField
                        className="blaze-zipcode-textarea"
                        value={brand.description}
                        label="Brand Description"
                        placeholder="Brand Description"
                        multiline
                        fullWidth
                        rows={6}
                        variant="outlined"
                        onChange={(e) => onChangehandler('description', e.target.value)}
                    />
                    {errors.description && <p className="error">{errors.description}</p>}
                </Grid>

                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <Button onClick={onAddBrand} variant="contained" color="primary" fullWidth>Submit</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}