import React from 'react';
import { Button, Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomInput from '../../components/common/CustomInput';
import CustomMultiSelect from '../../components/common/CustomMultiSelect';
import GooglePlaces from '../../components/common/GooglePlaces';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';



function TruckFormUI(props) {
    const classes = useStyles();

    const {
        data,
        allTerminalList = [],
        handleChangeInventory,
        handleChange,
        allTimeZones = [],
        allTimes = [],
        handleChangeOrderingHours,
        errors,
        handleChangeLocation,
        handleDeleteZipCode,
        handleAddZipcode
    } = props

    const { name, terminal, timeZone, orderingHours = [], zipcode = [], address = {} } = data

    return (
        <Grid container className="trucks-form-container">

            <Grid item xs={12} sm={6} className="trucks-form">
                <Typography variant="h4">Terminal Name </Typography>
                <CustomInput
                    placeholder="Terminal Name"
                    name="name"
                    value={name || ""}
                    required
                    onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                    error={errors.name}
                    className="w-100"
                />
            </Grid>
            <Grid item xs={12} sm={6} className="trucks-form">
                <Typography variant="h4">Blaze Terminal</Typography>
                <CustomMultiSelect
                    placeholder="Blaze Terminal"
                    name={"name"}
                    options={allTerminalList || []}
                    handleChange={(e, val) => { handleChange("terminal", val) }}
                    value={terminal || {}}
                    multiple={false}
                    required
                    className="w-100"
                />
                {errors && errors.inventory && <p className="error">{errors.inventory}</p>}
            </Grid>
            {/* <Grid item xs={12} sm={6} className="trucks-form">
                <Typography variant="h4">Time Zone </Typography>
                <CustomMultiSelect
                    placeholder="Time Zone"
                    name={"label"}
                    options={allTimeZones || []}
                    handleChange={(e, val) => { handleChange("timeZone", val) }}
                    value={timeZone || {}}
                    multiple={false}
                    required
                    className="w-100"
                />
                {errors && errors.timeZone && <p className="error">{errors.timeZone}</p>}
            </Grid> */}
            <Grid item xs={12} className="trucks-form">
                <Typography variant="h4">Location </Typography>
                <GooglePlaces
                    handleChange={handleChangeLocation}
                    data={address}
                />
                <div className="map-sec-container">
                    <div className="map-sec" id="pe-maps">
                    </div>
                </div>

            </Grid>

            <Grid item xs={12} className="d-flex">
                <Grid item xs={12} sm={6} className="trucks-form">
                    <Typography variant="h4">Delivery Hours </Typography>
                    <div className="w-100">
                        {orderingHours && orderingHours.length ? orderingHours.map((item, index) => {
                            const { startTime, endTime, day } = item
                            return (
                                <div key={index} className="ordering-hours-listing">
                                    <Typography className="ordering-hours-title">{day || ""} </Typography>
                                    <CustomMultiSelect
                                        label="Start Time"
                                        name={"label2"}
                                        options={allTimes || []}
                                        handleChange={(e, val) => { handleChangeOrderingHours("startTime", val, index) }}
                                        value={{ label2: startTime } || []}
                                        multiple={false}
                                        required
                                        className="w-25"
                                    />
                                    <CustomMultiSelect
                                        label="EndTime"
                                        name={"label2"}
                                        options={allTimes || []}
                                        handleChange={(e, val) => { handleChangeOrderingHours("endTime", val, index) }}
                                        value={{ label2: endTime } || []}
                                        multiple={false}
                                        required
                                        className="w-25"
                                    />
                                </div>
                            )
                        })
                            :
                            null
                        }
                    </div>
                </Grid>


                <Grid item xs={12} sm={6} className="trucks-form">
                    <Typography variant="h4" className="zipcode-title">
                        <span>ZipCodes</span>
                        <AddCircleOutlineOutlinedIcon className="add-zipcode-icon" onClick={() => { handleAddZipcode() }} />
                    </Typography>
                    <div className="d-flex f-wrap">
                        {
                            zipcode && zipcode.length ? zipcode.map((item, index) => {
                                return (
                                    <div key={index} >
                                        <div className="m-1">
                                            <Chip label={item || ""} onDelete={() => { handleDeleteZipCode(index) }} color="primary" />
                                        </div>
                                    </div>
                                )
                            })
                                :
                                <div>
                                    No data found
                                </div>
                        }
                    </div>
                </Grid>

            </Grid>
        </Grid>

    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default TruckFormUI

