import React, { useMemo } from 'react';
import Card from '@material-ui/core/Card';
import { flowerTypeImg, getPercentage, getProductUnitByCategoryId } from '../../utils';

export default function ProductCommonCard(props) {
    const { data = {} } = props;
    const {
        name = "",
        unitPrice = "",
        assets = [],
        flowerType = "",
        priceBreaks,
        thc,
        potencyAmount,
        cbd,
        bmwCategoryName,
        cbn
    } = data;

    let salesPrice = (priceBreaks && priceBreaks.length && priceBreaks[0] && priceBreaks[0].salePrice) || null;

    salesPrice = (salesPrice === 0) || (unitPrice === salesPrice) ? null : salesPrice
    const unitTypeByCategoryName = getProductUnitByCategoryId(bmwCategoryName, { thc, cbd, cbn }, potencyAmount)

    return <Card className="cus-pro-list">
        {
            salesPrice ?
                <span className="flower-type">{getPercentage(unitPrice, salesPrice) || 0}% Off</span>
                : null
        }

        <div className="pro-img">
            <img
                src={(assets && assets[0] && assets[0].thumbURL) || "/images/placeholderproduct.svg"}
                alt="Loading..."
                // onError="this.onerror = null; this.src='/images/placeholderproduct.svg' "
            />
        </div>
        <div className="pro-card-header">
            <div className="pro-mood">
                <span>
                    {flowerTypeImg(flowerType)}
                </span>
                <p>THC: {unitTypeByCategoryName.thc}</p>
            </div>
            <div className="pro-title">
                <h5>{name} </h5>
            </div>
            <div className="pro-price">
                <span className={salesPrice ? "pro-price-amount-del" : "pro-price-amount"}>
                    <span className="price-currency-symbol">$</span>{Number(unitPrice).toFixed(2)}
                </span>
                {
                    salesPrice ?
                        <span className="pro-price-amount">
                            <span className="price-currency-symbol">$</span>{Number(salesPrice).toFixed(2) || ""}
                        </span>
                        : null
                }
            </div>

        </div>
    </Card>
}