import {
    GET_ALL_TRUCKS,
    GET_ALL_TRUCKS_FAILED,
    GET_ALL_TRUCKS_SUCCESSFULL,
    GET_TRUCK_BY_ID,
    GET_ALL_TERMINALS,
    GET_ALL_TERMINALS_SUCCESSFULL,
    GET_ALL_TERMINALS_FAILED,
    GET_TRUCK_BY_ID_FAILED,
    GET_TRUCK_BY_ID_SUCCESSFULL
} from "../actions/trucks"

const initialState = {
    isLoading: false,
    allTrucksData: {},
    allTerminals: { values: [] }
}

export default function trucks(state = { ...initialState }, action = {}) {
    switch (action.type) {

        //get All Trucks
        case GET_ALL_TRUCKS:
            return { ...state, isLoading: true }

        case GET_ALL_TRUCKS_SUCCESSFULL:
            return { ...state, allTrucksData: action.data || {}, isLoading: false }

        case GET_ALL_TRUCKS_FAILED:
            return { ...state, isLoading: false }

        //get All Inventories
        case GET_ALL_TERMINALS:
            return { ...state, isLoading: true }

        case GET_ALL_TERMINALS_SUCCESSFULL:
            let values = []
            if (action.data.values && action.data.values.length) {
                values = action.data.values.filter(item => item.active)
            }
            return { ...state, allTerminals: { values } || {}, isLoading: false }

        case GET_ALL_TERMINALS_FAILED:
            return { ...state, isLoading: false }

        //get Truck By Id
        case GET_TRUCK_BY_ID:
            return { ...state, isLoading: true }

        case GET_TRUCK_BY_ID_SUCCESSFULL:
            return { ...state, isLoading: false }

        case GET_TRUCK_BY_ID_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}