import { createNewTruckAPI, getAllTerminalsAPI, getAllTrucksAPI, getTruckbyIdAPI, googlePlacesAPI, updateTruckAPI } from "../api/trucks";





export const GET_ALL_TRUCKS = 'GET_ALL_TRUCKS';
export const GET_ALL_TRUCKS_SUCCESSFULL = 'GET_ALL_TRUCKS_SUCCESSFULL';
export const GET_ALL_TRUCKS_FAILED = 'GET_ALL_TRUCKS_SUCCESSFULL';

export function getAllTrucks(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_TRUCKS
        })
        return new Promise((resolve, reject) => {
            getAllTrucksAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_TRUCKS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_TRUCKS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}



export const GET_ALL_TERMINALS = 'GET_ALL_TERMINALS';
export const GET_ALL_TERMINALS_SUCCESSFULL = 'GET_ALL_TERMINALS_SUCCESSFULL';
export const GET_ALL_TERMINALS_FAILED = 'GET_ALL_TERMINALS_SUCCESSFULL';

export function getAllTerminals(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_TERMINALS
        })
        return new Promise((resolve, reject) => {
            getAllTerminalsAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_TERMINALS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_TERMINALS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const CREATE_NEW_TRUCK = 'CREATE_NEW_TRUCK';
export const CREATE_NEW_TRUCK_SUCCESSFULL = 'CREATE_NEW_TRUCK_SUCCESSFULL';
export const CREATE_NEW_TRUCK_FAILED = 'CREATE_NEW_TRUCK_SUCCESSFULL';

export function createNewTruck(data) {
    return dispatch => {
        dispatch({
            type: CREATE_NEW_TRUCK
        })
        return new Promise((resolve, reject) => {
            createNewTruckAPI(data)
                .then((res) => {
                    dispatch({
                        type: CREATE_NEW_TRUCK_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: CREATE_NEW_TRUCK_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const GET_TRUCK_BY_ID = 'GET_TRUCK_BY_ID';
export const GET_TRUCK_BY_ID_SUCCESSFULL = 'GET_TRUCK_BY_ID_SUCCESSFULL';
export const GET_TRUCK_BY_ID_FAILED = 'GET_TRUCK_BY_ID_SUCCESSFULL';

export function getTruckById(data) {
    return dispatch => {
        dispatch({
            type: GET_TRUCK_BY_ID
        })
        return new Promise((resolve, reject) => {
            getTruckbyIdAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_TRUCK_BY_ID_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_TRUCK_BY_ID_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

export const UPDATE_TRUCK = 'UPDATE_TRUCK';
export const UPDATE_TRUCK_SUCCESSFULL = 'UPDATE_TRUCK_SUCCESSFULL';
export const UPDATE_TRUCK_FAILED = 'UPDATE_TRUCK_SUCCESSFULL';

export function updateTruck(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_TRUCK
        })
        return new Promise((resolve, reject) => {
            updateTruckAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_TRUCK_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_TRUCK_FAILED
                    })
                    return reject(err)
                })
        })
    }
}



export const GOOGLE_PLACES = 'GOOGLE_PLACES';
export const GOOGLE_PLACES_SUCCESSFULL = 'GOOGLE_PLACES_SUCCESSFULL';
export const GOOGLE_PLACES_FAILED = 'GOOGLE_PLACES_SUCCESSFULL';

export function googlePlaces(data) {
    return dispatch => {
        dispatch({
            type: GOOGLE_PLACES
        })
        return new Promise((resolve, reject) => {
            googlePlacesAPI(data)
                .then((res) => {
                    dispatch({
                        type: GOOGLE_PLACES_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GOOGLE_PLACES_FAILED
                    })
                    return reject(err)
                })
        })
    }
}
