import { CLOSE_MODAL_DIALOG, OPEN_MODAL_DIALOG } from "../actions/common"


const initialState = {
    modalData: {},
    isLoading: false,
    modal: "",
}

export default function (state = { ...initialState }, action = {}) {

    switch (action.type) {

        case OPEN_MODAL_DIALOG: {
            return { ...state, modal: action.modal, modalData: action.data }
        }

        case CLOSE_MODAL_DIALOG: {
            return { ...state, modal: "", modalData: action.data }
        }

        default:
            return state
    }
}