import React, { useState } from 'react';
import { Button, Typography, Grid, TextField, Box } from '@material-ui/core';
import * as actions from "../../redux/actions/products"
import * as commonActions from "../../redux/actions/common"
import { useDispatch } from 'react-redux';
import * as productsActions from "../../redux/actions/products"

export default function ThresholdModal(props) {
    const dispatch = useDispatch();
    const { modalData } = props;
    const [threshold,setThreshold] = useState("");
    const [msg,setMsg]=useState('')
    const productThreshold = modalData.thresholdValue;

    const onChangeHandler = (e)=>{
        const{value=""} =e.target;
        setThreshold(+value);  
       
    };
    
    const onSetThresholdHandler = async(e)=>{
        if (threshold>=0){
            await dispatch(actions.updateThreshold({threshold:threshold}));
            dispatch(commonActions.closeCustomModalDialog());
            dispatch(productsActions.getThreshold())
        }else{
              setMsg(`Value can't be less then zero`)
        }
    };

    return (
        <Grid container spacing={1} component="main" className="add-zipcode-modal-container">
            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">Set Threshold</Typography>
            </Grid>

            <Grid item xs={12} className="add-category-input">
            <Box my={2}>
                <Grid item xs={12}>
                   <Typography variant="h6">
                        Current Threshold: {productThreshold}
                    </Typography>
                </Grid>
            </Box>
                <Grid item xs={12}>
                    <TextField
                        className="blaze-zipcode-textarea"
                        type="number"
                        label="Set New Threshold"
                        value={threshold}
                        name="thresholdValue"
                        fullWidth
                        variant="outlined"
                        onChange={onChangeHandler}
                    />
                    <p style={{color:"red"}}>{msg}</p>
                </Grid>

                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={onSetThresholdHandler}
                    >
                        Set Threshold
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

