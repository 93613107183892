import {
    getAllPromotionAPI,
    getSpecialsProductsAPI,
    syncPromotionAPI,
    updatePromotionAPI,
    updateSpecialProductAPI,
    deletePromotionAPI,
    addParmotionAPI
} from "../api/promotions";

import { toast } from 'react-toastify';


export const GET_SPECIALS_PRODUCTS = 'GET_SPECIALS_PRODUCTS';
export const GET_SPECIALS_PRODUCTS_SUCCESSFULL = 'GET_SPECIALS_PRODUCTS_SUCCESSFULL';
export const GET_SPECIALS_PRODUCTS_FAILED = 'GET_SPECIALS_PRODUCTS_FAILED';

export function getSpecialProducts(data) {
    return dispatch => {
        dispatch({
            type: GET_SPECIALS_PRODUCTS
        })
        return new Promise((resolve, reject) => {
            getSpecialsProductsAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_SPECIALS_PRODUCTS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_SPECIALS_PRODUCTS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const UPDATE_SPECIALS_PRODUCTS = 'UPDATE_SPECIALS_PRODUCTS';
export const UPDATE_SPECIALS_PRODUCTS_SUCCESSFULL = 'UPDATE_SPECIALS_PRODUCTS_SUCCESSFULL';
export const UPDATE_SPECIALS_PRODUCTS_FAILED = 'UPDATE_SPECIALS_PRODUCTS_FAILED';

export function updateSpecialProducts(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_SPECIALS_PRODUCTS
        })
        return new Promise((resolve, reject) => {
            updateSpecialProductAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_SPECIALS_PRODUCTS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_SPECIALS_PRODUCTS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}



export const SYNC_PROMOTIONS = 'SYNC_PROMOTIONS';
export const SYNC_PROMOTIONS_SUCCESSFULL = 'SYNC_PROMOTIONS_SUCCESSFULL';
export const SYNC_PROMOTIONS_FAILED = 'SYNC_PROMOTIONS_FAILED';

export function syncPromotion(data) {
    return new Promise((resolve, reject) => {
        syncPromotionAPI(data)
            .then((res) => {
                toast.success(res.message)
                return resolve(res)
            })
            .catch(err => {
                return reject(err)
            })
    })
}


export const GET_ALL_PROMOTIONS = 'GET_ALL_PROMOTIONS';
export const GET_ALL_PROMOTIONS_SUCCESSFULL = 'GET_ALL_PROMOTIONS_SUCCESSFULL';
export const GET_ALL_PROMOTIONS_FAILED = 'GET_ALL_PROMOTIONS_FAILED';

export function getAllPromotion(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_PROMOTIONS
        })
        return new Promise((resolve, reject) => {
            getAllPromotionAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_PROMOTIONS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_PROMOTIONS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const UPDATE_PROMOTIONS = 'UPDATE_PROMOTIONS';
export const UPDATE_PROMOTIONS_SUCCESSFULL = 'UPDATE_PROMOTIONS_SUCCESSFULL';
export const UPDATE_PROMOTIONS_FAILED = 'UPDATE_PROMOTIONS_FAILED';

export function updatePromotion(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_PROMOTIONS
        })
        return new Promise((resolve, reject) => {
            updatePromotionAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_PROMOTIONS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_PROMOTIONS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

export const DELETE_PROMOTION = 'DELETE_PROMOTION';
export const DELETE_PROMOTION_SUCCESSFULL = 'DELETE_PROMOTION_SUCCESSFULL';
export const DELETE_PROMOTION_FAILED = 'DELETE_PROMOTION_FAILED';

export function deletePromotion(data) {
    return dispatch => {
        dispatch({
            type: DELETE_PROMOTION
        })
        return new Promise((resolve, reject) => {
            deletePromotionAPI(data)
                .then((res) => {
                    dispatch({
                        type: DELETE_PROMOTION_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_PROMOTION_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const ADD_PROMOTION_PRODUCTS = 'ADD_PROMOTION_PRODUCTS';
export const ADD_PROMOTION_PRODUCTS_SUCCESSFULL = 'ADD_PROMOTION_PRODUCTS_SUCCESSFULL';
export const ADD_PROMOTION_PRODUCTS_FAILED = 'ADD_PROMOTION_PRODUCTS_FAILED';

export function addPromotionProducts(data) {
    return dispatch => {
        dispatch({
            type: ADD_PROMOTION_PRODUCTS
        })
        return new Promise((resolve, reject) => {
            addParmotionAPI(data)
                .then((res) => {
                    dispatch({
                        type: ADD_PROMOTION_PRODUCTS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ADD_PROMOTION_PRODUCTS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}