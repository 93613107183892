
import {
    GET_ALL_ADMIN,
    GET_ALL_ADMIN_SUCCESS,
    GET_ALL_ADMIN_FAILED,
    ADD_ADMIN,
    ADD_ADMIN_SUCCESS,
    ADD_ADMIN_FAILED,
    DELETE_ADMIN,
    DELETE_ADMIN_SUCCESS,
    DELETE_ADMIN_FAILED
} from "../actions/user"


const initialState = {
    allAdmins: [],
    totalAdmins: 0,
    allAdminLoader: false,
    adminLoader: false
}

export default function user(state = { ...initialState }, action = {}) {
    switch (action.type) {
        case GET_ALL_ADMIN: {
            return { ...state, allAdminLoader: true }
        }
        case GET_ALL_ADMIN_SUCCESS: {
            const { values = [], total = 0 } = action.payload || {}
            return { ...state, allAdminLoader: false, allAdmins: values, totalAdmins: total }
        }
        case GET_ALL_ADMIN_FAILED: {
            return { ...state, allAdminLoader: false }
        }
        case ADD_ADMIN: {
            return { ...state, adminLoader: true }
        }
        case ADD_ADMIN_SUCCESS: {
            return { ...state, adminLoader: false }
        }
        case ADD_ADMIN_FAILED: {
            return { ...state, adminLoader: false }
        }
        case DELETE_ADMIN: {
            return { ...state, allAdmins: true }
        }
        case DELETE_ADMIN_SUCCESS: {
            return { ...state, allAdmins: false }
        }
        case DELETE_ADMIN_FAILED: {
            return { ...state, allAdmins: false }
        }
        default: {
            return state
        }
    }
}
