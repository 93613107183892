import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ManageProducts from './ManageProducts';
import { apiGet } from '../../utils';



function ManageProductsPage() {
    const classes = useStyles();

    return (
        <Grid container component="main" className={`${classes.root} dashboard-container`}>
            <ManageProducts />
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default ManageProductsPage