import React, { useEffect } from 'react';
import { Button, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTerminals, getAllTrucks } from '../../redux/actions/trucks';
import { useHistory } from 'react-router-dom';

import AllTrucks from './AllTrucks';
import CommonLoading from '../../components/common/CommonLoading';


function TrucksPage(props) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()


    const { allTrucksData = {}, isLoading } = useSelector(state => state.trucks)


    const { values: trucksValues = [] } = allTrucksData || {}


    useEffect(() => {
        dispatch(getAllTrucks()).then(() => { }).catch(() => { })
        dispatch(getAllTerminals()).then(() => { }).catch(() => { })
    }, [])


    const addTruck = () => {
        history.push("/terminal/add")
    }

    const handleEdit = (val) => {
        history.push(`/terminal/edit/${val._id}`)
    }

    return (
        <Grid container component="main" className={`${classes.root} trucks-container`}>
            <Grid item xs={12} className="top-btn-sec">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { addTruck() }}
                    className=""
                >
                    {false ? <CircularProgress size={20} style={{ color: "white" }} /> : 'Add Terminal'}
                </Button>
            </Grid>

            <Grid item xs={12} className="">
                <AllTrucks
                    trucksValues={trucksValues}
                    handleEdit={handleEdit}
                />
            </Grid>

            <CommonLoading isLoading={isLoading} />
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        // height: '100vh',
    },

}));


export default TrucksPage