import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Button, Typography, FormGroup, Grid } from '@material-ui/core';
import CustomInput from '../../components/common/CustomInput';
import * as actions from "../../redux/actions/appointments"
import * as commonActions from "../../redux/actions/common"
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../constants';
import CustomCheckbox from '../../components/common/CustomCheckbox';
import { isEmpty } from "lodash"
import { validateZipcodeData } from '../../utilities/validations/zipcode';

const initialAppointment = {
    serviceArea: "",
    zipcode: "",
    orderMin: "",
    expressDelivery: false,
    byAppointmentDelivery: false
}

function ZipCodeModal(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)

    const [appointment, setAppointment] = useState({ ...initialAppointment })
    const [errors, setErrors] = useState({ ...initialAppointment })

    const { title = "Add", data: editData = {} } = modalData



    useEffect(() => {
        if (!isEmpty(editData)) {
            setAppointment(editData)
        }
    }, [])

    const onChangehandler = (e) => {
        let { name, value } = e.target
        if ((name === "orderMin") && isNaN(Number(value))) {
            return
        }
        if (name === "orderMin") {
            value = value.split('.')
            if (value && value[1] && value[1].length > 2) return
            value = value.join('.')
            if (value === '') value = ''
        }
        if (name === "zipcode" && value.length > 11) return
        if (name === "serviceArea" && value.length > 30) return

        setAppointment({ ...appointment, [name]: value })
        setErrors({ ...errors, [name]: "" })

    }

    const onChangeDeliveryCheck = (key, value) => {
        let updatedData = { ...appointment, [key]: value }
        if (key == "expressDelivery" && updatedData.expressDelivery) {
            updatedData = { ...updatedData, byAppointmentDelivery: true }
        }

        if (key == "byAppointmentDelivery" && updatedData.expressDelivery) {
            return
        }


        setAppointment(updatedData)
    }

    const addZipCode = () => {

        const { errors, isValid } = validateZipcodeData(appointment)
        setErrors(errors);

        if (isValid) {
            dispatch(actions.createAppointment(appointment, (editData && editData._id || ""))).then(res => {
                dispatch(actions.getAllAppointments())
                dispatch(commonActions.closeCustomModalDialog())
            }).catch()
        }

    }

    return (
        <Grid container component="main" className={`${classes.root} add-zipcode-modal-container`}>

            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} Appointment</Typography>
            </Grid>
            <Grid item xs={12} className="add-category-input">
                <Grid item xs={12}>
                    <CustomInput
                        name="zipcode"
                        value={appointment.zipcode || ""}
                        onChange={onChangehandler}
                        label="ZIP Code"
                        className="w-100"
                        margin="normal"
                        disabled={!isEmpty(editData)}
                        error={errors.zipcode}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        name="serviceArea"
                        value={appointment.serviceArea || ""}
                        onChange={onChangehandler}
                        label="Service Area"
                        className="w-100"
                        margin="normal"
                        error={errors.serviceArea}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        name="orderMin"
                        value={appointment.orderMin || ""}
                        onChange={onChangehandler}
                        label="Order Min"
                        className="w-100 "
                        margin="normal"
                        error={errors.orderMin}
                    />
                </Grid>

                <FormGroup row={false}>
                    <CustomCheckbox
                        label="Express Delivery"
                        onChange={e => onChangeDeliveryCheck("expressDelivery", e.target.checked)}
                        value={appointment.expressDelivery || false}
                    />
                    <CustomCheckbox
                        label="By Appointment Delivery"
                        onChange={e => onChangeDeliveryCheck("byAppointmentDelivery", e.target.checked)}
                        value={appointment.byAppointmentDelivery || false}
                    />

                </FormGroup>
                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <Button onClick={addZipCode} variant="contained" color="primary" fullWidth>Submit</Button>
                </Grid>
            </Grid>

        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default ZipCodeModal