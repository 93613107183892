
import {
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESSFULL,
    GET_ALL_USERS_FAILED,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATIONS_SUCCESSFULL,
    UPDATE_NOTIFICATIONS_FAILED
} from "../actions/notifications"


const initialState = {
    isLoading: false,
    allUsers: []
}

export default function (state = { ...initialState }, action = {}) {
    switch (action.type) {

        //get all Users
        case GET_ALL_USERS:
            return { ...state, isLoading: true }

        case GET_ALL_USERS_SUCCESSFULL:
            return { ...state, allUsers: action.data.values || [], isLoading: false }

        case GET_ALL_USERS_FAILED:
            return { ...state, isLoading: false }

        //Send Notification
        case UPDATE_NOTIFICATION:
            return { ...state, isLoading: true }

        case UPDATE_NOTIFICATIONS_SUCCESSFULL:
            return { ...state, isLoading: false }

        case UPDATE_NOTIFICATIONS_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}