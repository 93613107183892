import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { GOOGLE_PLACES_KEY } from '../../constants';


function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function GooglePlaces(props) {
    const classes = useStyles();

    const { handleChange, data, label = "Set your location" } = props


    const [value, setValue] = React.useState(data || null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);


    const loaded = React.useRef(false);

    useEffect(() => {
        setValue(data)
    }, [data])


    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue, componentRestrictions: { country: "us" } }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });
        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const handleValue = (newValue) => {
        setValue(newValue || null);
        handleChange(newValue || null)
    }
    // console.log(options, value, "options")
    return (
        <Autocomplete
            id="google-map-demo"
            className="cust-google-field"
            getOptionLabel={(option) => { return (typeof option === 'string' ? option : option.description) || "" }}
            filterOptions={(x) => x}
            options={[...options].filter(item => Object.keys(item).length)}
            autoComplete
            includeInputInList
            // filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                handleValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} placeholder={label} variant="outlined" fullWidth />
            )}
            // getOptionSelected={(option, value) => option.place_id === value.place_id}
            renderOption={(option = {}) => {
                const matches = option && option.structured_formatting && option.structured_formatting.main_text_matched_substrings || [];
                const parts = (option && option.structured_formatting) ? parse(
                    ((option && option.structured_formatting && option.structured_formatting.main_text) || ""),
                    matches && matches.length && matches.map((match) => [match.offset, match.offset + match.length]),
                ) : []
                // console.log(matches, parts, "matches, parts")
                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            {parts && parts.length && parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                                {option && option.structured_formatting && option.structured_formatting.secondary_text || ""}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}