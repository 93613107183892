import { isEmpty } from "lodash";


const eRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegx = /^[0-9]+$/;
const charRegx = /^[a-zA-Z]+$/


export function validateLogin(data = {}) {
    let errors = {};


    if (!data.email) {
        errors.email = "Email is required!";
    }

    if (data.email && !eRegex.test(data.email)) {
        errors.email = "Invalid email!";
    }

    if (!data.password) {
        errors.password = "Password is required!";
    }

    // if (data.password && data.password.length < 6) {
    //     errors.password = "Password must be atleast 6 characters long!";
    // }

    return {
        isValid: isEmpty(errors),
        errors
    }
}