import {
  GET_DELIVERY_HOURS,
  GET_DELIVERY_HOURS_SUCCESSFULL,
  GET_DELIVERY_HOURS_FAILED,
  DELETE_DELIVERY_HOURS,
  DELETE_DELIVERY_SUCCESSFULL,
  DELETE_DELIVERY_FAILED
} from "../actions/deliveryHours";

const initialState = {
  isLoading: false,
  allDeliveryHourData: {}
};


export default function deliveryHours(state = { ...initialState }, action = {}) {
  switch (action.type) {
    case GET_DELIVERY_HOURS:
      return { ...state, isLoading: true };

    case GET_DELIVERY_HOURS_SUCCESSFULL:
      return { ...state, allDeliveryHourData: action.data || {}, isLoading: false };

    case GET_DELIVERY_HOURS_FAILED:
      return { ...state, isLoading: false };

    case DELETE_DELIVERY_HOURS:
      return {
      ...state,
        isLoading: true
      };
      case DELETE_DELIVERY_SUCCESSFULL:
        return { ...state, isLoading: false }

    case DELETE_DELIVERY_FAILED:
        return { ...state, isLoading: false }

    default:
      return state;
  }

}
