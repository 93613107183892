import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';


export default function CustomDialog(props) {

    const { children, customFunction = () => { }, maxWidth, className = "cus-modal" } = props;

    const [open, setModalOpen] = useState(true)


    function handleClose() {
        setModalOpen(false)
        customFunction();
    }

    return (
        <div className="">
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={maxWidth}
                className={className}
                fullWidth
            >
                <DialogTitle className="modal-title">
                    <IconButton onClick={handleClose} className="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dailogBox">
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    );
}
