import {
    getAllOrdersAPI
} from "../api/orders";

// import { toast } from 'react-toastify';

export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const GET_ALL_ORDERS_SUCCESSFULL = 'GET_ALL_ORDERS_SUCCESSFULL';
export const GET_ALL_ORDERS_FAILED = 'GET_ALL_ORDERS_FAILED';

export function getAllOrders(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_ORDERS
        })
        return new Promise((resolve, reject) => {
            getAllOrdersAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_ORDERS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_ORDERS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

