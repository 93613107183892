import { apiGet, apiPost, apiPut, apiDelete } from "../../utils";

export function getDeliveryHoursAPI() {
    return apiGet("api/v1/admin/delivery");
}

export function createNewDeliveryHourAPI(data) {
    return apiPost('api/v1/admin/delivery', data);
}

export function updateDeliverHoursAPI(id, data) {
    return apiPut(`api/v1/admin/delivery/${id}`, data);
}

export function deleteDeliverHoursAPI(id) {
    return apiDelete(`api/v1/admin/delivery/${id}`)
}