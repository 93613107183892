import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"

import { Button, Grid, FormGroup, CircularProgress, FormControl, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/common/CustomInput';
import CustomCheckbox from '../../components/common/CustomCheckbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CommonLoading from '../../components/common/CommonLoading';
import CustomSelect from '../../components/common/CustomSelect';
import CommonSideDrawer from '../../components/common/CommonSideDrawer';

import * as productsActions from "../../redux/actions/products"
import * as   actions from "../../redux/actions/notifications"

import { validateNotificationForm } from '../../utilities/validations/notification'

import { debounce } from "lodash";
import { getProductData, getOptionLabel, getSelectedLabel, getSelectedId, typeOptions, RadioFormOption } from '../../utils'

import UserDrawer from './UserDrawer';

const initialNotificationForm = {
    deviceType: '',
    userId: [],
    title: '',
    message: '',
    isEmail: false,
    isSMS: false,
    isPush: false,
    type: '',
    selectedId: ''
}

function Notification() {
    const classes = useStyles();
    const router = useHistory();

    const [notificationForm, setNotificationForm] = useState({ ...initialNotificationForm })
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const { isLoading, allUsers = [] } = useSelector(state => state.notifications)
    const { allProducts = {}, allCategoryData = {} } = useSelector(state => state.products)
    const [sideDrawer, setSideDrawer] = useState(false)
    // const [selectedUsers, setSelectedUsers] = useState([])
    const [search, setSearch] = useState('')

    const delayedQuery = useCallback(debounce(query => sendQuery(query), 500), [])

    useEffect(() => {
        dispatch(actions.getAllUsers())
        dispatch(productsActions.getAllProducts())
        dispatch(productsActions.getAllCategory())
    }, [])


    const handleSearch = (e) => {
        setSearch(e.target.value)
        delayedQuery(e.target.value)
    }

    const sendQuery = (query) => {
        dispatch(actions.getAllUsers({ search: query }))

    }

    const onChangeNotificationForm = (name, value) => {
        // if (name == 'type' && value) {
        //     setNotificationForm({ ...notificationForm, selectedId: {} })
        // }
        setErrors({ ...errors, [name]: '' })
        setNotificationForm({ ...notificationForm, [name]: value })
    }


    const handleChangedeviceId = (e, val) => {
        setErrors({ ...errors, selectedId: '' })
        setNotificationForm({ ...notificationForm, selectedId: val })
    }



    const isValid = () => {
        const { isValid, errors, isDrawserOpen } = validateNotificationForm(notificationForm)
        setErrors(errors)
        if (isDrawserOpen) {
            setSideDrawer(true)
        }
        return isValid
    }


    const submitNotificationForm = (e) => {
        e.preventDefault();
        if (isValid()) {
            const { userId, type, selectedId } = notificationForm
            let updatedData = {
                ...notificationForm,
                userId: userId || [],
                selectedId: getSelectedId(type, selectedId) || ''
            }
            if (type == "GENERAL") delete updatedData.selectedId;

            dispatch(actions.updateNotification(updatedData)).then(res => {
                router.push('/notification')
                setNotificationForm({ ...initialNotificationForm })
                dispatch(actions.getAllUsers())
            }).catch()
        }
    }



    const handleChangeDevice = (e) => {
        const { name, value } = e.target;

        if (name === 'USER' && value)
            setSideDrawer(true)
        else
            setSideDrawer(false)

        setErrors({ ...errors, deviceType: '' })

        setNotificationForm({
            ...notificationForm, deviceType: value, userId: []
        });
    }


    //Sidebar drawer
    const handleSubmit = (selectedUsers) => {
        setNotificationForm({
            ...notificationForm,
            userId: selectedUsers
        });
        setSearch('')
        setSideDrawer(false);

    }

    const handleClose = () => {
        if (search) {
            delayedQuery('')
        }
        setSearch('')
        setNotificationForm({
            ...notificationForm,
            deviceType: ''
        })
        setSideDrawer(false)
    }


    let listOptions = getProductData(notificationForm.type, allProducts, allCategoryData)

    return (
        <Grid container>
            <Grid item xs={4} className="add-notification-grid-item">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="mb-1">
                            <FormControl>
                                <label style={{ marginBottom: 10 }}>Device Type</label>
                                <RadioGroup row value={notificationForm.deviceType}>
                                    {
                                        RadioFormOption.map(res => {
                                            return <FormControlLabel
                                                value={res.name}
                                                control={<Radio color="primary" />}
                                                label={res.label}
                                                labelPlacement="top"
                                                onChange={(e) => handleChangeDevice(e)}
                                                name={res.label}
                                            />
                                        })
                                    }
                                </RadioGroup>
                                {errors?.deviceType ? <span className="error">{errors.deviceType}</span> : null}
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} className="mt-1">
                        <CustomSelect
                            label="Type"
                            value={notificationForm?.type || ''}
                            onChange={e => onChangeNotificationForm("type", e.target.value)}
                            menuItem={typeOptions}
                            errors={errors?.type || ''}
                        />
                    </Grid>
                    {
                        notificationForm.type !== "GENERAL" ?
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    value={notificationForm.selectedId || {}}
                                    options={listOptions || []}
                                    getOptionLabel={(option) => getOptionLabel(option, notificationForm?.type || '') || ''}
                                    renderInput={(params) => <TextField {...params} label={getSelectedLabel(notificationForm?.type)} variant="outlined" />}
                                    onChange={(e, val) => { handleChangedeviceId(e, val) }}
                                    fullWidth
                                    renderOption={(option, { selected }) => {
                                        return (
                                            <React.Fragment>
                                                {getOptionLabel(option, notificationForm?.type || '')}
                                                {/* {option.adminProductName} */}
                                            </React.Fragment>
                                        )
                                    }
                                    }
                                />
                                {errors?.selectedId ? <span className="error">{errors.selectedId}</span> : null}

                            </Grid> : null
                    }

                    <Grid item xs={12} >
                        <CustomInput
                            name="title"
                            value={notificationForm?.title || ""}
                            onChange={e => onChangeNotificationForm("title", e.target.value)}
                            label="Title"
                            className="w-100"
                            margin="normal"
                            error={errors.title}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <CustomInput
                            name="message"
                            value={notificationForm?.message || ""}
                            onChange={e => onChangeNotificationForm("message", e.target.value)}
                            label="Message"
                            className="w-100 pt-1"
                            margin="normal"
                            error={errors.message}
                        />
                    </Grid>
                    <Grid item xs={12} className="add-category-input">
                        <FormGroup >
                            <CustomCheckbox
                                label="Email"
                                onChange={e => onChangeNotificationForm("isEmail", e.target.checked)}
                                value={notificationForm?.isEmail || false}
                            />
                            <CustomCheckbox
                                label="SMS"
                                onChange={e => onChangeNotificationForm("isSMS", e.target.checked)}
                                value={notificationForm?.isSMS || false}
                            />
                            <CustomCheckbox
                                label="Push Notification"
                                onChange={e => onChangeNotificationForm("isPush", e.target.checked)}
                                value={notificationForm?.isPush || false}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20, width: 100 }}>
                        <Button variant="contained" color="primary" onClick={submitNotificationForm} fullWidth>Submit</Button>
                    </Grid>

                    <CommonLoading isLoading={isLoading} />

                </Grid>
                <CommonSideDrawer anchor={'right'} open={sideDrawer}>
                    <UserDrawer
                        allUsers={allUsers}
                        errors={errors}
                        setErrors={setErrors}
                        handleClose={handleClose}
                        handleSubmit={handleSubmit}
                        search={search}
                        handleSearch={handleSearch}
                        isLoading={isLoading}
                    />
                </CommonSideDrawer>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default Notification;

